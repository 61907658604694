body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two items per row */
  gap: 10px; /* Gap between items */
  list-style: none;
  padding: 0;
}

.option {
  padding: 10px;
  border: 1px solid #ccc;
  color: #333;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.option i {
  margin-right: 8px;
}

.option:hover {
  background-color: #f0f0f0;
}